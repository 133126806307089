<template>
  <section class="preview-screen background-01">
    <h1 class="preview-screen__heading">404</h1>
    <p class="preview-screen__subheading">
      Извините, такой игры у нас нет
    </p>
    <router-link to="/" class="back-button">Назад к играм</router-link>
  </section>
</template>
<script>
export default {
  name: 'Page404',
};
</script>
<style lang="scss" scoped>
@import '../styles/common';
.back-button {
  @extend .button;
  @extend .button_card-for;
  color: #2e2e38;
}
</style>
